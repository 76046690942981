<template>
  <b-modal
    id="modal-detach-commercials-from-channel"
    :title="$t('table.detachCommercialsFromChannel')"
    :ok-disabled="!selectedChannel || isModalBusy"
    :cancel-title="$t('table.cancel')"
    :busy="isModalBusy"
    @show="openingModal"
    @ok.prevent="detachCommercialsFromChannel"
  >
    <SpinnerLoader v-if="channelsStatus !== 'success'" :loading="channelsStatus" />
    <form v-else @submit.stop.prevent="detachCommercialsFromChannel">
      <input type="submit" value="Submit" class="hidden-submit" />

      <b-form-group :label="$t('broadcastSideBar.selectChannel')">
        <MultiSelect
          v-model="selectedChannel"
          label="name"
          track-by="id"
          :options="channelsList"
          :placeholder="$t('broadcastSideBar.selectChannel')"
          :disabled="isModalBusy"
        ></MultiSelect>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import MultiSelect from '@/components/MultiSelect.vue';

export default {
  name: 'ModalDetachCommercialsFromChannel',
  components: { SpinnerLoader, MultiSelect },
  data() {
    return {
      isModalBusy: false,
      selectedChannel: null,
    };
  },
  computed: {
    ...mapGetters({
      channelsList: 'getChannelsList',
      channelsStatus: 'getChannelsStatus',
    }),
  },
  methods: {
    async openingModal() {
      this.selectedChannel = null;
      this.$store.dispatch('GET_CHANNELS', { per_page: 1000 });
    },
    async detachCommercialsFromChannel() {
      if (!this.selectedChannel) return;
      const modalResult = await this.$bvModal
        .msgBoxConfirm(
          this.$i18n.t('table.detachCommercialsFromChannelModalAlert', [this.selectedChannel.name]) +
            ` \n` +
            this.$i18n.t('table.actionCannotBeUndone') +
            ` \n` +
            this.$i18n.t('table.areYouSure'),
          {
            title: this.$i18n.t('table.confirmAction'),
            size: 'sm',
            okTitle: this.$i18n.t('table.yes'),
            cancelTitle: this.$i18n.t('table.no'),
            autoFocusButton: 'ok',
            id: 'confirm-detach-commercials-from-channel-modal',
          }
        )
        .catch((err) => {});
      if (!modalResult) return;
      this.isModalBusy = true;
      await this.$store.dispatch('POST_DETACH_COMMERCIALS_FROM_CHANNEL', {
        channel_id: this.selectedChannel.id,
        formData: {
          handle_commercials: true,
        },
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.selectedChannel = null;
          this.$bvModal.hide('modal-detach-commercials-from-channel');
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },
  },
};
</script>

<style></style>
